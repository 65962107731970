import axios from 'axios'
import axiosRetry from 'axios-retry'
import router from '../router'
import store from '@/store'
import {
    getApiUrl,
    getToken,
    getTokenExpiration,
    getRefreshToken,
    setToken,
    setRefreshToken,
    setTokenExpiration
} from '@/utils/auth'
import VueJwtDecode from 'vue-jwt-decode'

const api_url = getApiUrl()

// create an axios instance
const service = axios.create({
    baseURL: api_url.base_api, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 0 // request timeout
})

axiosRetry(service, { retries: 3, retryDelay: axiosRetry.exponentialDelay })

// request interceptor
service.interceptors.request.use(
    (config) => {
        // do something before request is sent
        let originalRequest = config
        let endpoint = originalRequest.url
        if (store.getters.token) {
            var current_time = new Date().getTime() / 1000
            if (current_time > getTokenExpiration() - 60  && !(endpoint.includes('logout'))) {
                return store.dispatch('user/getRefreshToken', getRefreshToken()).then((res) => {
                    var access_token = res.response.data.access_token
                    var refresh_token = res.response.data.refresh_token

                    setToken(access_token)
                    setRefreshToken(refresh_token)

                    const decodedJwtToken = VueJwtDecode.decode(access_token) // decode jwt token
                    setTokenExpiration(decodedJwtToken.exp)

                    originalRequest.headers['authtoken'] = getToken()

                    return Promise.resolve(originalRequest)
                })
            } else {
                originalRequest.headers['authtoken'] = getToken()

                return Promise.resolve(originalRequest)
            }
        }
        return config
    },

    (error) => {
        // do something with request error
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    (response) => {
        const res = response

        // if the custom code is not 20000, it is judged as an error.
        if (res.status !== 200 && res.status !== 201) {
            alert({
                message: 'Error',
                type: 'error',
                duration: 5 * 1000
            })

            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
            }
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    (error) => {
        if (error.response.status == 409) {
            store.dispatch('user/logout');
            router.push('/login');
        }else{
            return Promise.reject(error)
        }
    }
)

export default service
